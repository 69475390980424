import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { View, PageTitle, Jumbotron } from '../components';

const PageNotFoundView = () => {
    return (
        <View>
            <PageTitle title="404" />
            <Jumbotron text="404" subText="Page Not Found" />
            <Container>
                <Row className="mb-5">
                    <Col xs={12}>
                        <p>Sorry we can't seem to find the page you're looking for.</p>
                    </Col>
                </Row>
            </Container>
        </View>
    );
};

export default PageNotFoundView;