import React from 'react';

interface JumbotronProps {
    readonly text: string;
    readonly subText: string;
}

const Jumbotron = (props: JumbotronProps) => {
    return (
        <div className="jumbotron">
            <div className="container">
                <h1 className="display-3">{props.text}</h1>
                <p>{props.subText}</p>
            </div>
        </div>
    );
};

export default Jumbotron;