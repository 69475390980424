import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Container, Nav } from 'react-bootstrap';

const TopBar = () => {
    const brandClicked = (event) => {
        event.preventDefault();
    };

    return (
        <header>
            <Navbar variant="dark" className="bg-grey">
                <Container>
                    <Navbar.Brand onClick={brandClicked}>
                        <strong>MyIP</strong>
                    </Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link as={NavLink} to="/" exact>Home</Nav.Link>
                        <Nav.Link as={NavLink} to="/api-usage">API</Nav.Link>
                        <Nav.Link as={NavLink} to="/faq">FAQ</Nav.Link>
                        <Nav.Link as={NavLink} to="/dedicated">Dedicated</Nav.Link>
                        <Nav.Link as={NavLink} to="/stats">Stats</Nav.Link>
                        <Nav.Link as={NavLink} to="/contact">Contact</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </header>       
    );
};

export default TopBar;