import React from 'react';
import { Container, Button } from 'react-bootstrap';
import {UnControlled as CodeMirror} from 'react-codemirror2';
import _ from 'lodash';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/dracula.css';

import 'codemirror/mode/xml/xml.js';
import 'codemirror/mode/css/css.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'codemirror/mode/clike/clike.js';
import 'codemirror/mode/lua/lua.js';
import 'codemirror/mode/go/go.js';
import 'codemirror/mode/php/php.js';
import 'codemirror/mode/powershell/powershell.js';
import 'codemirror/mode/shell/shell.js';
import 'codemirror/mode/ruby/ruby.js';
import 'codemirror/mode/python/python.js';
import 'codemirror/mode/perl/perl.js';

import { View, PageTitle, Toast } from '../components';
import Utils from '../utils';

const WEBAPI_BASE_URL = process.env.REACT_APP_WEBAPI_BASE_URL;
const EXAMPLE_CACHE_KEY = 'cached.examples';

const getExamplesFromCache = (): any => {
    const json = localStorage.getItem(EXAMPLE_CACHE_KEY);

    if (Utils.isNullOrWhitespace(json)) {
        return null;
    }

    try {
        const obj = JSON.parse(json);
        const now = new Date();

        if (obj.expires < now.getTime()) {
            localStorage.removeItem(EXAMPLE_CACHE_KEY);

            return null;
        }

        return obj.examples;
    } catch (e) {
        console.log('Error: ', e);

        return null;
    }
};

const setExamplesInCache = (examples: any) => {
    const now = new Date();
    const expires = new Date();

    expires.setDate(now.getDate() + 7);

    const cachedExamples = {
        expires: expires.getTime(),
        examples
    };
    const json = JSON.stringify(cachedExamples);

    localStorage.setItem(EXAMPLE_CACHE_KEY, json);
};

const getExamplesFromHttp = async (): Promise<any> => {
    try {
        const response = await fetch(`${WEBAPI_BASE_URL}/api-examples`);

        if (!response.ok) {
            return Promise.resolve(null);
        }

        const result = await response.json();

        return Promise.resolve(result);
    } catch (e) {
        console.log('Error: ', e);

        return Promise.resolve(null);
    }
};

const loadExamples = async (): Promise<any> => {
    const cachedExamples = getExamplesFromCache();

    if (!_.isNil(cachedExamples)) {
        return Promise.resolve(cachedExamples);
    }

    const examples = await getExamplesFromHttp();

    if (!_.isNil(examples)) {
        setExamplesInCache(examples);
    }

    return Promise.resolve(examples);
};

const buildCodeExamples = (codes: any): any[] => {
    let keys = [];

    for(const key in codes) {
        keys.push(key);
    }

    keys = keys.sort();

    const results = [];

    for(let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];
        const code = codes[key];
        const el = (
            <div key={key} className="card margin-bottom">
                <div className="card-header d-flex justify-content-between align-items-center">
                    {code.title}
                    <Button size="sm" variant="outline-secondary" onClick={() => Utils.copyCode(code.code)}>Copy</Button>
                </div>
                <div className="card-block">
                    <CodeMirror
                        value={code.code}
                        options={{
                            mode: code.lang,
                            theme: 'dracula',
                            lineNumbers: true,
                            lineWrapping: true,
                            readOnly: 'nocursor'
                        }}
                    />
                </div>
            </div>
        );

        results.push(el);
    }

    return results;
};

const ApiView = () => {
    const [basicExamples, setBasicExamples] = React.useState({});
    const [codeExamples, setCodeExamples] = React.useState(null);

    const getBasicExample = (name: string): string => {
        if (!basicExamples.hasOwnProperty(name)) {
            return null;
        }

        return basicExamples[name];
    };

    React.useEffect(() => {
        let disposed = false;

        (async () => {
            try {
                const examples = await loadExamples();

                if (disposed || _.isNil(examples)) {
                    return;
                }
    
                setBasicExamples(examples.responses);
                setCodeExamples(buildCodeExamples(examples.codes));
            } catch (e) {
                console.log('Error: ', e);
    
                setBasicExamples(null);
                setCodeExamples(null);
            }
        })();

        return () => {
            disposed = true;
        };
    }, []);

    return (
        <View>
            <PageTitle title="API" />
            <Container className="margin-top margin-bottom">               
                <h2>API Usage</h2>
                <br />
                <p>MyIP provides a blisteringly simple HTTP based API you can call and we also provide responses in a variety of formats. Below you will find the various URL endpoints you can call with an example of their expected response.</p>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>URL</th>
                            <th>Response Type</th>
                            <th>Example</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><code>https://api.my-ip.io/v2/ip.txt</code></td>
                            <td>Plain Text</td>
                            <td><pre className="nohighlight">{getBasicExample('text')}</pre></td>
                        </tr>
                        <tr>
                            <td><code>https://api.my-ip.io/v2/ip.json</code></td>
                            <td>JSON</td>
                            <td><pre className="nohighlight">{getBasicExample('json')}</pre></td>
                        </tr>
                        <tr>
                            <td><code>https://api.my-ip.io/v2/ip.jsonp</code></td>
                            <td>JSONP</td>
                            <td><pre className="nohighlight">{getBasicExample('jsonp')}</pre></td>
                        </tr>
                        <tr>
                            <td><code>https://api.my-ip.io/v2/ip.jsonp?callback=name</code></td>
                            <td>JSONP (with named callback)</td>
                            <td><pre className="nohighlight">{getBasicExample('jsonp')?.replace('callback', 'name')}</pre></td>
                        </tr>
                        <tr>
                            <td><code>https://api.my-ip.io/v2/ip.xml</code></td>
                            <td>XML</td>
                            <td><pre className="nohighlight">{getBasicExample('xml')}</pre></td>
                        </tr>
                        <tr>
                            <td><code>https://api.my-ip.io/v2/ip.yml</code></td>
                            <td>Yaml</td>
                            <td><pre className="nohighlight">{getBasicExample('yaml')}</pre></td>
                        </tr>
                        <tr>
                            <td><code>https://api.my-ip.io/ip.csv</code></td>
                            <td>CSV</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <p>You will generally call our API using the host <code>api.my-ip.io</code>, however we also have hosts if you just need to target a specific IP version, for example if you're only running on one specific stack. Each is listed below.</p>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Host</th>
                            <th>Supported Versions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><code>api.my-ip.io</code></td>
                            <td>IPv4, IPv6</td>
                        </tr>
                        <tr>
                            <td><code>api4.my-ip.io</code></td>
                            <td>IPv4</td>
                        </tr>
                        <tr>
                            <td><code>api6.my-ip.io</code></td>
                            <td>IPv6</td>
                        </tr>
                    </tbody>
                </table>
            </Container>
            <Container className="margin-bottom">
                <h2>Examples</h2>
                <br />
                <p>Below you can find examples of how to call MyIP in a variety of languages. If you have a missing example or a better example please feel free to send it us.</p>
                {codeExamples}
            </Container>
            <Container className="margin-bottom">
                <h2>Libraries</h2>
                <br />
                <p>Don't want to implement everything yourself? You can use one of our free libraries below.</p>
                <p><strong>NOTE:</strong> Don't see a library for your programming language of choice? If you create one we'll be happy to link to it below. Just send us an e-mail with the details.</p>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Library URL</th>
                            <th>Language</th>
                            <th>Author</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><pre><a href="https://github.com/Workshell/myip-dotnet" target="_blank" rel="noreferrer">https://github.com/Workshell/myip-dotnet</a></pre></td>
                            <td><pre>.NET (C#)</pre></td>
                            <td><pre>Workshell Ltd</pre></td>
                        </tr>
                        <tr>
                            <td><pre><a href="https://github.com/Workshell/myip-js" target="_blank" rel="noreferrer">https://github.com/Workshell/myip-js</a></pre></td>
                            <td><pre>JavaScript</pre></td>
                            <td><pre>Workshell Ltd</pre></td>
                        </tr>
                    </tbody>
                </table>
            </Container>
            <Toast />
        </View>
    );

};

export default ApiView;