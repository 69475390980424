import React from 'react';
import { Container } from 'react-bootstrap';

const year = (new Date()).getUTCFullYear();

const Footer = () => {
    return (
        <footer>
            <Container>
                <hr/>
                <p>&copy;{year} Workshell Ltd. All rights reserved.</p>
            </Container>
        </footer>
    );
};

export default Footer;