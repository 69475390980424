import React from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';

import { View, PageTitle, Toast } from '../components';
import Utils from '../utils';

const WEBAPI_BASE_URL = process.env.REACT_APP_WEBAPI_BASE_URL;
const CAPTCHA_SITE_KEY = '6LcpVOAbAAAAACz6vDK93nJAvLWufK_aR9xEr8yi';

const ContactView = () => {
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [captcha, setCaptcha] = React.useState(null);
    const [sending, setSending] = React.useState(false);
    const [canSend, setCanSend] = React.useState(false);

    const emailChanged = (event) => setEmail(event.target.value);
    const messageChanged = (event) => setMessage(event.target.value);

    const onCaptcha = (value) => setCaptcha(value); 
    const onExpired = () => setCaptcha(null);
    const onErrored = () => setCaptcha(null);

    const resetCaptcha = React.useCallback(() => {
        const grecaptcha: any = window['grecaptcha'];

        grecaptcha.reset();
    }, []);

    const sendMessage = async () => {
        if (sending) {
            return;
        }

        setSending(true);

        try {
            const formData = new FormData();

            formData.append('email', email);
            formData.append('message', message);
            formData.append('captcha', captcha);

            const response = await fetch(`${WEBAPI_BASE_URL}/send-message`, {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                Toast.error('Could not send message, please try again.', null, true);
                resetCaptcha();

                return;
            }

            Toast.success('Message successfully sent.');
            setEmail('');
            setMessage('');
            setCaptcha(null);
            resetCaptcha();
        } catch (e) {
            console.log('Error: ', e);

            Toast.error('Could not send message, please try again.', null, true);
            resetCaptcha();
        } finally {
            setSending(false);
        }
    };

    const calculateCanSend = React.useCallback(() => {
        let result = false;

        if (sending) {
            return false;
        } else if (!Utils.isValidEmail(email)) {
            result = false;
        } else if (Utils.isNullOrWhitespace(message) || message.length < 5) {
            result = false;
        } else if (Utils.isNullOrWhitespace(captcha)) {
            result = false;
        } else {
            result = true;
        }

        return result;
    }, [email, message, captcha, sending]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCanSend(calculateCanSend());
        }, 125);

        return () => {
            clearInterval(interval);
        };
    }, [calculateCanSend]);

    return (
        <View>
            <PageTitle title="Contact" />
            <Container className="margin-top margin-bottom">
                <h2>Contact</h2>
                <br />
                <p>Need to get hold of us? Fill in the form below to send us a message, we'd be happy to hear from you with your queries, ideas and suggestions!</p>
                <br />
                <Form>
                    <Form.Group className="mb-4">
                        <Form.Label>Your e-mail address:</Form.Label>
                        <Form.Control type="email" placeholder="name@example.com" disabled={sending} value={email} onChange={emailChanged} onKeyDown={emailChanged} />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Message:</Form.Label>
                        <Form.Control as="textarea" rows={3} disabled={sending} value={message} onChange={messageChanged} onKeyDown={messageChanged} />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} onChange={onCaptcha} onExpired={onExpired} onErrored={onErrored} />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Button variant="primary" disabled={!canSend} onClick={sendMessage}>Send Message</Button>
                    </Form.Group>
                </Form>
                <div className="clearfix margin-bottom"></div>
                <Toast />
            </Container>
        </View>
    );
};

export default ContactView;