import React from 'react';
import { Container } from 'react-bootstrap';

import { View, PageTitle } from '../components';

const DedicatedView = () => {

    return (
        <View>
            <PageTitle title="Dedicated" />
            <Container className="margin-top margin-bottom">
                <h2>Dedicated</h2>
                <br />
                <p>Although MyIP is generally free to use by anyone, the public facing API comes with no support and no service level agreement. It might be up. It might be down. It might respond quickly. It might not respond at all!</p>
                <p>To resolve the above issues we offer dedicated instances and service level agreements. We can offer multiple types of deployment depending on your usage scenarios:</p>
                <ul>
                    <li>Single instance</li>
                    <li>Load balanced multi-instance</li>
                    <li>Load balanced cross-region multi-instance</li>
                </ul>
                <p>If you are an organisation that wants dependebility and predictability when using our API please get in touch with us you'll be suprised how little it can cost. A single instance for example can cost you as little as $20/mo<sup>(excl. VAT)</sup>.</p>
            </Container>
        </View>
    );

};

export default DedicatedView;