import React from 'react';

import Utils from '../utils';

interface PageTitleProps {
    readonly title?: string;
}

const PageTitle = (props: PageTitleProps) => {

    React.useEffect(() => {
        if (Utils.isNullOrWhitespace(props?.title)) {
            document.title = 'MyIP';
        } else {
            document.title = `${props.title} - MyIP`;
        }      
    }, [props.title]);

    return (
        <React.Fragment>           
        </React.Fragment>
    );

};

export default PageTitle;