import { Toast } from './components';
import _ from 'lodash';

const WHITESPACE_REGEX: RegExp = /^\s*$/i;
const EMAIL_REGEX: RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;

export default class Utils {

    public static isNullOrEmpty(value: any): boolean {
        if (_.isNil(value)) {
            return true;
        }

        if (_.isArray(value) || _.isString(value)) {
            return (value.length === 0);
        }

        throw new Error('Only supports arrays or strings.');
    }

    public static isNullOrWhitespace(value: string): boolean {
        if (_.isNil(value)) {
            return true;
        }

        if (!_.isString(value)) {
            return false;
        }

        return WHITESPACE_REGEX.test(value as string);
    }

    public static isValidEmail(value: string) {
        if (this.isNullOrWhitespace(value)) {
            return false;
        }

        return EMAIL_REGEX.test(value as string);
    }

    public static copyAddress(address: string): void {
        this.copyToClipboard(address);
        Toast.success('IP address copied to clipboard.', null, false);
    }

    public static copyCode(code: string): void {
        this.copyToClipboard(code);
        Toast.success('Code copied to clipboard.', null, false);
    }

    private static copyToClipboard(value: string): void {
        const el = document.createElement('textarea');    // Create a <textarea> element

        el.value = value;                                 // Set its value to the string that you want copied
        el.setAttribute('readonly', '');                  // Make it readonly to be tamper-proof
        el.style.position = 'absolute';
        el.style.left = '-9999px';                        // Move outside the screen to make it invisible

        document.body.appendChild(el);                    // Append the <textarea> element to the HTML document

        const selected =
            document.getSelection().rangeCount > 0        // Check if there is any content selected previously
                ? document.getSelection().getRangeAt(0)   // Store selection if found
                : false;                                  // Mark as false to know no selection existed before

        el.select();                                      // Select the <textarea> content

        document.execCommand('copy');                     // Copy - only works as a result of a user action (e.g. click events)
        document.body.removeChild(el);                    // Remove the <textarea> element

        if (selected) {                                   // If a selection existed before copying
            document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
            document.getSelection().addRange(selected);   // Restore the original selection
        }
    }

}