import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';

import { View, PageTitle, Jumbotron, Toast } from '../components';
import Utils from '../utils';

const createCacheKey = (version: number): string => {
    return `cached.lookup.ipv${version}`;
};

const getLookupFromCache = (version: number): string => {
    const cacheKey = createCacheKey(version);
    const json = localStorage.getItem(cacheKey);

    if (Utils.isNullOrWhitespace(json)) {
        return null;
    }

    try {
        const obj = JSON.parse(json);
        const now = new Date();

        if (obj.expires < now.getTime()) {
            localStorage.removeItem(cacheKey);

            return null;
        }

        return obj.value;
    } catch (e) {
        console.log('Error: ', e);

        return null;
    }
};

const setLookupInCache = (version: number, value: string) => {
    const now = new Date();
    const expires = new Date(now.getTime() + 5 * 60000);

    const cachedLookup = {
        expires: expires.getTime(),
        value
    };
    const json = JSON.stringify(cachedLookup);
    const cacheKey = createCacheKey(version);

    localStorage.setItem(cacheKey, json);
};

const getLookupFromHttp = async (version: number): Promise<string> => {
    try {
        const response = await fetch(`https://api${version}.my-ip.io/v2/ip.json`);

        if (response.ok) {
            const result = await response.json();

            if (!result.success) {
                return null;
            }

            return result.ip;
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
};

const lookup = async (version: number): Promise<string> => {
    const cachedValue = getLookupFromCache(version);

    if (!Utils.isNullOrWhitespace(cachedValue)) {
        return Promise.resolve(cachedValue);
    }

    const value = await getLookupFromHttp(version);

    if (!Utils.isNullOrWhitespace(value)) {
        setLookupInCache(version, value);

        return Promise.resolve(value);  
    }

    return Promise.resolve(null);
};

const HomeView = () => {
    const [ipv4, setIPv4] = React.useState(null);
    const [ipv6, setIPv6] = React.useState(null);

    React.useEffect(() => {
        lookup(4).then((address: string) => {
            setIPv4(address);
        });
        lookup(6).then((address: string) => {
            setIPv6(address);
        });
    }, []);

    let ipv4El: any = null;

    if (!Utils.isNullOrWhitespace(ipv4)) {
        ipv4El = (
            <div className="card margin-bottom">
                <div className="card-header">
                    IPv4
                </div>
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <samp className="card-title">{ipv4}</samp>
                        <Button size="sm" variant="primary" onClick={() => Utils.copyAddress(ipv4)}>Copy</Button>
                    </div>
                </div>
            </div>
        );
    }

    let ipv6El: any = null;

    if (!Utils.isNullOrWhitespace(ipv6)) {
        ipv6El = (
            <div className="card margin-bottom">
                <div className="card-header">
                    IPv6
                </div>
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <samp className="card-title">{ipv6}</samp>
                        <Button size="sm" variant="primary" onClick={() => Utils.copyAddress(ipv6)}>Copy</Button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <View>
            <PageTitle />
            <Jumbotron text="What's my IP?" subText="A simple web service to show you your current IP address supporting both IPv4 and IPv6." />
            <Container>
                <p>Ever needed to get your public IP address programmatically? Maybe you're setting up new cloud services and need to know your IP, maybe you're behind a firewall and need to tunnel information. Whatever the reason, sometimes knowing your public IP address can be useful.</p>
                <p>You can find your current public IP address below. Depending on your supported connectivity an address for either IPv4, IPv6 or both should be displayed.</p>
                <br/>

                {ipv4El}
                {ipv6El}

                <p>Want to use this in an application or service? Then don't bother trying to scrape this page, call our <Link to="/api-usage">API</Link> instead.</p>
            </Container>
            <Toast />
        </View>
    );
};

export default HomeView;