import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Bar, Pie } from 'react-chartjs-2';
import _ from 'lodash';

import { View, PageTitle, Jumbotron } from '../components';
import Utils from '../utils';

const WEBAPI_BASE_URL = process.env.REACT_APP_WEBAPI_BASE_URL;
const STATS_CACHE_KEY = 'cached.stats';

const getStatsFromCache = (checkExpires: boolean): any => {
    const json = localStorage.getItem(STATS_CACHE_KEY);

    if (Utils.isNullOrWhitespace(json)) {
        return null;
    }

    try {
        const obj = JSON.parse(json);

        if (checkExpires) {
            const now = new Date();

            if (obj.expires < now.getTime()) {
                return null;
            }
        }

        return obj.stats;
    } catch (e) {
        console.log('Error: ', e);

        return null;
    }
};

const setStatsInCache = (stats: any) => {
    const now = new Date();
    const expires = new Date(now.getTime() + (20 * 60000));

    const cachedExamples = {
        expires: expires.getTime(),
        stats
    };
    const json = JSON.stringify(cachedExamples);

    localStorage.setItem(STATS_CACHE_KEY, json);
};

const getStatsFromHttp = async (): Promise<any> => {
    try {
        const response = await fetch(`${WEBAPI_BASE_URL}/stats`);

        if (!response.ok) {
            return Promise.resolve(null);
        }

        const result = await response.json();

        return Promise.resolve(result);
    } catch (e) {
        console.log('Error: ', e);

        return Promise.resolve(null);
    }
};

const preloadStats = (): any => {
    const cachedStats = getStatsFromCache(false);

    return cachedStats;
};

const loadStats = async () => {
    const cachedStats = getStatsFromCache(true);

    if (!_.isNil(cachedStats?.stats)) {
        return Promise.resolve(cachedStats);
    }

    const stats = await getStatsFromHttp();

    if (!_.isNil(stats)) {
        setStatsInCache(stats);
    }

    return Promise.resolve(stats);
};

const barOptions = (label: string): any => {
    return {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: label
                },
                stacked: true,
                ticks: {
                    autoSkip: false
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Number of Requests'
                },
                stacked: true
            }
        }       
    };
};

const pieOptions: any = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        title: {
            display: true,
            text: 'Top 5 Countries',
            position: 'bottom'
        },
        legend: {
            position: 'right'
        }
    }
};

const StatsView = () => {
    const [state, setState] = React.useState(preloadStats());

    const requestTotalsLayout = React.useMemo(() => {
        return (
            <Row className="mb-5">
                <Col xs={12}>
                    <h3>Requests Totals</h3>
                    <hr />
                    <Table bordered>
                        <thead>
                            <tr>
                                <td></td>
                                <td><strong>IPv4</strong></td>
                                <td><strong>IPv6</strong></td>
                                <td><strong>Total</strong></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><strong>All Time</strong></td>
                                <td>{state?.allIPv4}</td>
                                <td>{state?.allIPv6}</td>
                                <td>{state?.allTotal}</td>
                            </tr>
                            <tr>
                                <td><strong>Current Year</strong></td>
                                <td>{state?.yearIPv4}</td>
                                <td>{state?.yearIPv6}</td>
                                <td>{state?.yearTotal}</td>
                            </tr>
                            <tr>
                                <td><strong>Current Month</strong></td>
                                <td>{state?.monthIPv4}</td>
                                <td>{state?.monthIPv6}</td>
                                <td>{state?.monthTotal}</td>
                            </tr>
                            <tr>
                                <td><strong>Current Day</strong></td>
                                <td>{state?.dayIPv4}</td>
                                <td>{state?.dayIPv6}</td>
                                <td>{state?.dayTotal}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        );
    }, [state]);

    const lastYearLayout = React.useMemo(() => {
        return (
            <Row className="mb-5">
                <Col xs={12}>
                    <h3>Last Year</h3>
                    <hr />
                </Col>
                <Col lg={7} md={12}>
                    <Bar data={state?.yearBarData} options={barOptions('Month')} />
                </Col>
                <Col lg={5} md={12}>
                    <Pie data={state?.yearPieData} options={pieOptions} width={null} height={null} style={{width: '100%', height: '100%'}} />
                </Col>
            </Row>
        );
    }, [state]);

    const last30DaysLayout = React.useMemo(() => {
        return (
            <Row className="mb-5">
                <Col xs={12}>
                    <h3>Last 30 Days</h3>
                    <hr />
                </Col>
                <Col lg={7} md={12}>
                    <Bar data={state?.thirtyBarData} options={barOptions('Day')} />
                </Col>
                <Col lg={5} md={12}>
                    <Pie data={state?.thirtyPieData} options={pieOptions} width={null} height={null} style={{width: '100%', height: '100%'}} />
                </Col>
            </Row>
        );
    }, [state]);

    const last7DaysLayout = React.useMemo(() => {
        return (
            <Row className="mb-5">
                <Col xs={12}>
                    <h3>Last 7 Days</h3>
                    <hr />
                </Col>
                <Col lg={7} md={12}>
                    <Bar data={state?.sevenBarData} options={barOptions('Weekday')} />
                </Col>
                <Col lg={5} md={12}>
                    <Pie data={state?.sevenPieData} options={pieOptions} width={null} height={null} style={{width: '100%', height: '100%'}} />
                </Col>
            </Row>
        );
    }, [state]);

    const lastDayLayout = React.useMemo(() => {
        return (
            <Row className="mb-5">
                <Col xs={12}>
                    <h3>Last Day</h3>
                    <hr />
                </Col>
                <Col lg={7} md={12}>
                    <Bar data={state?.dayBarData} options={barOptions('Hour')} />
                </Col>
                <Col lg={5} md={12}>
                    <Pie data={state?.dayPieData} options={pieOptions} width={null} height={null} style={{width: '100%', height: '100%'}} />
                </Col>
            </Row>
        );
    }, [state]);

    const lastUpdatedLayout = React.useMemo(() => {
        return (
            <Row>
                <Col xs={12}>
                    <p style={{width: '100%', textAlign: 'center'}}>Last Updated: {state?.lastUpdated}</p>
                </Col>
            </Row>
        );
    }, [state]);

    let statLayouts: any = null;

    if (!_.isNil(state)) {
        statLayouts = (
            <React.Fragment>
                {requestTotalsLayout}
                {lastYearLayout}
                {last30DaysLayout}
                {last7DaysLayout}
                {lastDayLayout}
                {lastUpdatedLayout}
            </React.Fragment>          
        );
    } else {
        statLayouts = (
            <Row className="mb-5">
                <Col>
                    <p>Loading statistics please wait...</p>
                </Col>
            </Row>
        );
    }

    React.useEffect(() => {
        let disposed = false;

        (async () => {
            try {
                const stats = await loadStats();

                if (disposed || _.isNil(stats)) {
                    return;
                }

                setState(stats);
            } catch (e) {
                console.log('Error: ', e);

                //setState(null);
            }
        })();

        return () => {
            disposed = true;
        }
    }, []);

    return (
        <View>
            <PageTitle title="Stats" />
            <Jumbotron text="Statistics" subText="Lies, damned lies and statistics. Updated hourly." />
            <Container>
                {statLayouts}
            </Container>
        </View>
    );
};

export default StatsView;