import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import { View, PageTitle } from '../components';

const FaqView = () => {

    return (
        <View>
            <PageTitle title="FAQ" />
            <Container className="margin-top margin-bottom">
                <h2>FAQ</h2>
                <br />
                <p>We created this web service originally to aid us with some projects where we needed to know the public IP of the network we were on and we've decided to open it up for general public consumption in the hope people will find a use for it.</p>
                <p>Below are some common questions people ask, if you don't find an answer you're looking for don't hesitate to contact us.</p>
                <br/>
                <ul>
                    <li>
                        <p><strong>How much do you cost?</strong></p>
                        <p>MyIP is free to use, you don't need an account you can just start calling us.<sup>1</sup></p>
                    </li>
                    <li>
                        <p><strong>Are there any limitations?</strong></p>
                        <p>You are free to call the service multiple times a day, however we do rate-limit and will throttle you if you call us too often. Under such circumstances you will recieve a 429 response code and are expected to backoff for a while. We also reserve the right to ban you for an unspecified amount of time if you abuse our services.<sup>2</sup></p>
                    </li>
                    <li>
                        <p><strong>Do you support IPv6?</strong></p>
                        <p>Yes! We fully support IPv6 along with the traditional IPv4.</p>
                    </li>
                    <li>
                        <p><strong>Do you perform any logging?</strong></p>
                        <p>We do not record your IP as a general rule, we do record metrics for service performance monitoring and the graphs but these are generated by aggregating request counts, no identifying information is recorded.</p>
                    </li>
                    <li>
                        <p><strong>How do we use your API?</strong></p>
                        <p>Check out our <Link to="/api-usage">API</Link> page for the details.</p>
                    </li>
                </ul>
                <br/><br/>
                <p className="small-print"><sup>1</sup>&nbsp;Although we do not charge and the service is free it's important to understand this is a shared service. We endeavour to provide an equal service to all, however under times of extreme load it's possible some requests may take longer to fulfill or even be dropped. We try to scale as demand requires and constantly monitor loads and adjust accordingly.</p>
                <p className="small-print"><sup>2</sup>&nbsp;If you are likely to require us to process a lot of requests, are a corporation and/or want a defined level of service then please contact us, we can provide a <a href="/dedicated">dedicated</a> isolated version at reasonable cost.</p>
            </Container>
        </View>
    );

};

export default FaqView;