import React from 'react';
import { Switch, Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './App.css';

import { HomeView, ApiView, StatsView, FaqView, DedicatedView, ContactView, PageNotFoundView } from './views';

const App = () => {
    return (
        <Switch>
            <Route path="/" exact>
                <HomeView />
            </Route>
            <Route path="/api-usage" exact>
                <ApiView />
            </Route>
            <Route path="/faq" exact>
                <FaqView />
            </Route>
            <Route path="/dedicated" exact>
                <DedicatedView />
            </Route>
            <Route path="/stats" exact>
                <StatsView />
            </Route>
            <Route path="/contact" exact>
                <ContactView />
            </Route>
            <Route>
                <PageNotFoundView />
            </Route>
        </Switch>
    );
};

export default App;
