import React from 'react';

import { Header, Footer } from './';

interface ViewProps {
    readonly children?: any;
}

const View = (props: ViewProps) => {

    return (
        <React.Fragment>
            <Header />
            {props.children}
            <Footer />
        </React.Fragment>
    );
    
};

export default View;