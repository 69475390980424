import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import _ from 'lodash';

import 'react-toastify/dist/ReactToastify.css';
import './toast.css';

export default class Toast extends React.PureComponent {

    public static readonly Success: string = 'Success';
    public static readonly Error: string = 'Error';

    /* Static Methods */

    public static info(message: string, title?: string, mustClose?: boolean): void {
        const preparedToast = this.prepareToast(message, title, mustClose);

        toast.info(preparedToast.message, preparedToast.config);
    }

    public static success(message: string, title?: string, mustClose?: boolean): void {
        const preparedToast = this.prepareToast(message, title, mustClose);

        toast.success(preparedToast.message, preparedToast.config);
    }

    public static warn(message: string, title?: string, mustClose?: boolean): void {
        const preparedToast = this.prepareToast(message, title, mustClose);

        toast.warn(preparedToast.message, preparedToast.config);
    }

    public static error(message: string, title?: string, mustClose?: boolean): void {
        const preparedToast = this.prepareToast(message, title, mustClose);

        toast.error(preparedToast.message, preparedToast.config);
    }

    private static prepareToast(message: string, title?: string, mustClose?: boolean): any {
        let preparedMessage: any;

        if (!_.isNil(title)) {
            preparedMessage = (
                <React.Fragment>
                    <div className="toast-title">
                        <strong>{title}</strong>
                    </div>
                    <div>
                        {message}
                    </div>
                </React.Fragment>
            )
        } else {
            preparedMessage = message;
        }

        const result: any = {
            message: preparedMessage,
            config: {
                position: 'bottom-right',
                autoClose: (mustClose ? false : undefined),
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            }
        };

        return result;
    }

    /* Methods */

    public render = () => {
        return (
            <ToastContainer />
        );
    }

}